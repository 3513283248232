import "./src/css/style.css";

import React from "react";
import PropTypes from "prop-types";

// import Amplify from "@aws-amplify/core";
// import awsconfig from "./src/aws-exports";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Amplify.configure(awsconfig);

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfZl70UAAAAAFSck7xLHWoC8y50dpiUAg20ourW">
      {element}
    </GoogleReCaptchaProvider>
  );
};

wrapRootElement.propTypes = {
  element: PropTypes.element
};
